import * as React from "react";
import {
  Typography,
  // Avatar,
  Stack,
  Toolbar,
  CssBaseline,
  Box,
  AppBar,
  // IconButton,
  useScrollTrigger,
  Slide,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LogoBig from "../assets/logo_curved.svg";
import LogoSmall from "../assets/logo.svg";
// import UsaLogo from "../assets/usaLogo.svg";
import { ContainedButton } from "../components/ui/Buttons";
import "./style.css";
// import { Search } from "@mui/icons-material";
// import { GetSellerData } from "../services/settings.services";
import useMediaQuery from "@mui/material/useMediaQuery";
import LogoutIcon from '@mui/icons-material/Logout';
import { GetAccountBalances } from "../services/dashboard.service";
import { GetDailySummaryBalance } from "../services/dailySummary.services";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide
      appear={false}
      direction="down"
      in={!trigger}
      sx={{ display: trigger ? "none" : "" }}

    >
      {children}
    </Slide>
  );
}

export default function Navigation(props) {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:1280px)");
  const matchesLogo = useMediaQuery("(min-width:900px)");
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const [accountBalance, setAccountBalance] = useState(null);
  const [summaryBalance, setSummaryBalance] = useState(null);

  const hasRun = useRef(false);

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      navigate("/login");
    }
    setUserData(localStorage.getItem("userName"));
    const pageTitle = location.pathname.slice(1) || 'Dashboard';
    document.title = 'VePay - ' + pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1);
    // (async () => {
    //   try {
    //     await GetSellerData().then((data) => {
    //       console.log(data)
    //       // if (data === null || data === undefined) {
    //       //   localStorage.clear();
    //       //   navigate("/login");
    //       // }
    //       setUserData(data);
    //     });
    //   } catch (error) {
    //     // localStorage.clear();
    //     // navigate("/login");
    //     throw error;
    //   }
    // })();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;

    const fetchData = async () => {
      try {

        await GetAccountBalances().then((data) => {
          setAccountBalance(data);
        });

        await GetDailySummaryBalance().then((data) => {
          setSummaryBalance(data);
        });

      } catch (error) {
        throw error;
      }
    };

    fetchData();
  }, []);

  if (!userData || !accountBalance || !summaryBalance) return null;

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#F1F5FC",
          minHeight: '100vh'
        }}
      >
        <CssBaseline />

        <AppBar
          sx={{ padding: "15px 15px 0 15px", backgroundColor: "#032878" }}
        >
          <HideOnScroll>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 4 }}
            >
              <Stack spacing={1} direction="row" alignItems="center" >
                {/* <IconButton sx={{ backgroundColor: "#868B98" }}>
                  <NotificationsOutlinedIcon sx={{ color: "#FFFFFF" }} />
                </IconButton> */}
                {/* <Stack direction="row" alignItems="center" spacing={1}>
                  <img src={UsaLogo} alt="USA" />
                  <Typography variant="body1" sx={{ display: { xs: 'none', sm: 'block' } }}>USD</Typography>
                </Stack> */}

              </Stack>
              <img src={LogoBig} alt="VePay" style={{ display: matchesLogo ? '' : 'none', width: '20%' }} />
              <img src={LogoSmall} alt="VePay" style={{ display: matchesLogo ? 'none' : '', width: '50%' }} />
              <Stack spacing={1} direction="row" alignItems="center">
                {/* <Avatar alt={userData.firstName} src="/static/images/avatar/1.jpg" /> */}
                <ContainedButton
                  variant="contained"
                  onClick={(e) => handleLogOut(e)}
                >
                  <LogoutIcon sx={{ display: { xs: 'block', sm: 'none' } }} />
                  <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>Sign Out</Typography>
                </ContainedButton>
              </Stack>
            </Stack>
          </HideOnScroll>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ overflowX: "auto", overflowY: "hidden", mt: 2 }}
          >
            <Stack direction="row" spacing={2}>
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive || !location.pathname.slice(1) ? "activeText" : "inActiveText"
                }
              >
                <Typography variant="h5_bold" noWrap component="div">
                  Dashboard
                </Typography>
              </NavLink>
              <NavLink
                to="/daily-summary"
                className={({ isActive }) =>
                  isActive ? "activeText" : "inActiveText"
                }
              >
                <Typography variant="h5_bold" noWrap component="div">
                  Daily Summary
                </Typography>
              </NavLink>
              <NavLink
                to="/withdrawals"
                className={({ isActive }) =>
                  isActive ? "activeText" : "inActiveText"
                }
              >
                <Typography variant="h5_bold" noWrap component="div">
                  Withdrawals
                </Typography>
              </NavLink>

              <NavLink
                to="/reports"
                className={({ isActive }) =>
                  isActive ? "activeText" : "inActiveText"
                }
              >
                <Typography variant="h5_bold" noWrap component="div">
                  Reports
                </Typography>
              </NavLink>
              <NavLink
                to="/help-support"
                className={({ isActive }) =>
                  isActive ? "activeText" : "inActiveText"
                }
              >
                <Typography variant="h5_bold" noWrap component="div">
                  Help
                </Typography>
              </NavLink>
              {/* <NavLink
                to="/configure"
                className={({ isActive }) =>
                  isActive ? "activeText" : "inActiveText"
                }
              >
                <Typography variant="h5" noWrap component="div">
                  Configure
                </Typography>
              </NavLink> */}
            </Stack>

            {/* <IconButton>
              <Search sx={{ color: "#FFFFFF" }} />
            </IconButton> */}
          </Stack>
        </AppBar>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pt: 10,
            pl: 2,
            pr: 2,
            maxWidth: matches ? "80%" : "100%",
          }}
        >
          <Toolbar />
          <Outlet context={[userData, accountBalance, summaryBalance]} />
        </Box>
      </Box>
    </>
  );
}
