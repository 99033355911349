import { BASE_URL } from "../utils/constants";
import handler from "../utils/handler";

export const RequestAccessToken = async(value) => {
    try {
        let response = await fetch(`${BASE_URL}/core/auth/openid-connect/seller-token`, {
            method: "POST",
            headers: {
                "Content-Type" : "application/json",
            },
            body: JSON.stringify(value)
        });

        let result = handler(response)

        if(response.ok){
            await result.then((data) => {
                localStorage.setItem("accessToken", data.access_token);
                localStorage.setItem("userName", `${data.firstName}`);
                localStorage.setItem("businessEncryptedId", data.businessEncryptedId);
            });

            const accessToken = localStorage.getItem("accessToken");
            const userName = localStorage.getItem("userName");

            if(accessToken !==null || userName !== null) {
                return response;
            }
        } else {
            return response;
        }

    } catch (e) {
        throw e;
    }
}

export const SellerSignUp = async(value) => {
    try {
        let response = await fetch(`${BASE_URL}/core/onboard/seller`, {
            method: "POST",
            headers: {
                "Content-Type" : "application/json",
            },
            body: JSON.stringify(value)
        });

        let result = handler(response)

        if(response.ok){
            await result.then((data) => {
                localStorage.setItem("uniqueId", data.uniqueId)
            });
        } else {
            return response;
        }

        return response;

    } catch (e) {
        throw e;
    }
}

export const PlatformAuth = async(value) => {
    try {
        let response = await fetch(`${BASE_URL}/core/onboard/platformauth`, {
            method: "POST",
            headers: {
                "Content-Type" : "application/json",
            },
            body: JSON.stringify(value)
        });

        let result = handler(response)

        if(response.ok){
            await result.then((data) => {
                localStorage.setItem("uniqueId", data.uniqueId)
                localStorage.setItem("platformId", data.platformId)
            });
        } else {
            return response;
        }
        

    } catch (e) {
        throw e;
    }
}

export const OnboardingLogin = async(value) => {
    try {
        let response = await fetch(`${BASE_URL}/core/onboard/login`, {
            method: "POST",
            headers: {
                "Content-Type" : "application/json",
            },
            body: JSON.stringify(value)
        });

        let result = handler(response)

        return {response, result};
        

    } catch (e) {
        throw e;
    }
}

export const PostBusinessInfomation = async(value) => {
    try {
        let response = await fetch(`${BASE_URL}/core/onboard/business`, {
            method: "POST",
            headers: {
                "Content-Type" : "application/json",
            },
            body: JSON.stringify(value)
        });

        let result = handler(response)

        if(response.ok){
            await result.then((data) => {
                localStorage.setItem("businessEncryptedId", data.encryptedId)
            });
        }

        return response;

    } catch (e) {
        throw e;
    }
}

export const PostBankInfomation = async(value) => {
    try {
        let response = await fetch(`${BASE_URL}/core/onboard/bank`, {
            method: "POST",
            headers: {
                "Content-Type" : "application/json",
            },
            body: JSON.stringify(value)
        });

        return response;

    } catch (e) {
        throw e;
    }
}