import React from "react";
import { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  Stack,
  Typography,
  Snackbar,
} from "@mui/material";
import { GreenContainedButton, TextButton } from "./ui/Buttons";
// import { CustomSwitch } from "../components/Switch";
import { StyledTextBoxLarge } from "./ui/TextBox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
// import { ExpandMore } from "@mui/icons-material";
import { formatCurrency } from "../utils/utilityFunctions";
import { NumericFormat } from "react-number-format";
import { PostWithdraw } from "../services/withdrawal.services"
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SuccessImage from "../assets/SuccessWallet.png";
import { useNavigate } from "react-router-dom";

export default function WithdrawModal(props) {
  const [open, setOpen] = useState(false);
  const [availableBalance, setAvailableBalance] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [withdraw, setWithdraw] = useState(null);
  const [snackBarSuccess, setSnackBarSuccess] = useState(false);
  const [snackBarAlert, setSnackBarAlert] = useState(false);
  const [openSuccessModal, setSuccessModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(props.status);
    setAvailableBalance(props.accountBalance.totalAvailableBalance);
    setWithdraw(props.withdrawData);

  }, [props]);

  const handleClose = () => {
    setOpen(false);
    setSuccessModalOpen(false)
    props.setModalCallback(false);
  };

  if (!availableBalance || !withdraw) return null;

  const handleSubmit = (event) => {
    event.preventDefault();

    try {
      PostWithdraw(withdrawAmount).then((data) => {
        if (data.ok) {
          setSnackBarSuccess(true);
          setOpen(false);
          setSuccessModalOpen(true);
        } else {
          setSnackBarAlert(true);
        }
      });
    } catch (error) {
      setSnackBarAlert(true);
      throw error;
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="xs"
      >
        <Box sx={{ p: 4 }}>
          <Stack
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
            spacing={2}
          >
            <Typography variant="h3">Available balance: </Typography>
            <Typography variant="h3" sx={{ color: "#00AD47" }}>
              {formatCurrency(availableBalance, true)}
            </Typography>
          </Stack>
          {/* <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Transfer Entire Balance</Typography>
            <CustomSwitch onChange={() => {
              setWithDrawFull(!withdrawFull);
              setWithdrawAmount(withdrawFull ? '' : Number(availableBalance).toFixed(2));
              }} />
          </Stack> */}
          <br></br>
          <Stack justifyContent="space-between" spacing={1}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography variant="body1">
                Withdrawal:
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontWeight: 400, color: "#093BA7", cursor: "pointer" }}
                onClick={() => {
                  setWithdrawAmount(Number(availableBalance).toFixed(2));
                }}
              >
                Tansfer All
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <NumericFormat
                value={withdrawAmount}
                error={withdrawAmount > Number(availableBalance).toFixed(2)}
                placeholder="Withdraw Amount"
                helperText={
                  withdrawAmount > Number(availableBalance).toFixed(2)
                    ? "Maximum transfer amount exceeded"
                    : ""
                }
                onValueChange={(values) => {
                  setWithdrawAmount(values.floatValue);
                }}
                // decimalScale={2}
                // disabled={withdrawFull}
                thousandSeparator=","
                prefix="$"
                customInput={StyledTextBoxLarge}
              />
            </Stack>
          </Stack>

          <br></br>
          <Divider />
          <br></br>
          <Stack justifyContent="space-between" spacing={1}>
            <Typography variant="body1">Transfer Method:</Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" spacing={1}>
                <Avatar alt="US" src="US" sx={{ bgcolor: "#fff" }}>
                  <AccountBalanceIcon sx={{ color: "#093BA7" }} />
                </Avatar>
                <Stack>
                  <Typography variant="h5">Direct Bank Transfer</Typography>
                  <Typography variant="h5" sx={{ fontWeight: 400 }}>
                    {withdraw.bankName} {withdraw.accNumber}
                  </Typography>
                </Stack>
              </Stack>
              <TextButton variant="text">
                Default
                {/* <ExpandMore /> */}
              </TextButton>
            </Stack>
          </Stack>
          <br></br>
          <Divider />
          <br></br>
          <Box
            sx={{
              p: 1.5,
              backgroundColor: "#FFFFFF",
              height: "100%",
              boxShadow: "0px 0px 10px rgba(3, 40, 120, 0.18)",
              borderRadius: "2px",
            }}
          >
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={6}>
                Account Funded:
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5" sx={{ fontWeight: 400 }}>
                  {formatCurrency(withdrawAmount || 0, true)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                Transfer Fee:
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5" sx={{ fontWeight: 400 }}>
                  {formatCurrency(0, true)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                Final Transfer Amount:
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5" sx={{ fontWeight: 400 }}>
                  {formatCurrency(withdrawAmount || 0, true)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <br></br>
          <Divider />
          <br></br>
          <Stack justifyContent="space-between" alignItems="center" spacing={1}>
            <Typography variant="body2">
              Transfers can take up to 1 to 2 business days
            </Typography>
            <GreenContainedButton
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              disabled={
                withdrawAmount <= 0 ||
                  withdrawAmount === undefined ||
                  withdrawAmount > Number(availableBalance).toFixed(2)
                  ? true
                  : false
              }
            >
              Withdraw Now
            </GreenContainedButton>
            <Button variant="outlined" fullWidth onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Dialog>
      <Snackbar
        open={snackBarSuccess}
        onClose={() => setSnackBarSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ top: "0 !important", left: "0 !important" }}
        autoHideDuration={6000}
      >
        <Box
          onClose={() => setSnackBarSuccess(false)}
          sx={{ width: "100vw", background: "#00AD47", p: 0.5 }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
            }}
          >
            <CheckCircleOutlineOutlinedIcon fontSize="small" />{" "}
            <Typography variant="body2">
              Transfer succsessful!
            </Typography>
          </Stack>
        </Box>
      </Snackbar>

      <Snackbar
        open={snackBarAlert}
        onClose={() => setSnackBarAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ top: "0 !important", left: "0 !important" }}
        autoHideDuration={6000}
      >
        <Box
          onClose={() => setSnackBarAlert(false)}
          sx={{ width: "100vw", background: "#F33535", p: 0.5 }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
            }}
          >
            <CancelOutlinedIcon fontSize="small" />{" "}
            <Typography variant="body2">
              There was an error processing the transfer please try again.
            </Typography>
          </Stack>
        </Box>
      </Snackbar>

      {/* Transfer Funds Success Modal Start */}
      <Dialog
        open={openSuccessModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <Box sx={{ p: 4 }}>
          <Typography id="modal-modal-title" variant="h4" textAlign="center">
            <b>Please Hold On!</b>
          </Typography>
          <br></br>
          <Stack justifyContent="center">
            <img
              src={SuccessImage}
              alt="Logo"
              style={{ width: "60%", marginLeft: "20%" }}
            />
          </Stack>
          <br></br>
          <Stack sx={{ marginBottom: "10%" }} spacing={2}>
            <Typography id="modal-modal-title" variant="h5" textAlign="center">
              Transfer request successfully initiated
            </Typography>
            <Typography id="modal-modal-title" variant="h5_light" textAlign="center">
              Your fund transfer request is processsing now. Please hold on for
              now, We will send a notification within 12 - 24 hours when it is
              done and transferd to your bank. Thank you.
            </Typography>
          </Stack>
          <Stack>
            <Button
              variant="contained"
              style={{ backgroundColor: "#7ED376" }}
              size="large"
              onClick={() => {
                handleClose();
                navigate(0);
              }}
            >
              Done
            </Button>
          </Stack>
          <br></br>
        </Box>
      </Dialog>
      {/* Transfer Funds Success Modal End */}
    </div>
  );
}
