import React, { useEffect } from "react";
import { useState, useRef } from "react";
import WithdrawCard from "../../components/Withdraw";
import {
	Typography,
	Stack,
	Box,
	Table,
	TableBody,
	TableRow,
	TableHead,
	Collapse,
	// IconButton,
	// Grid,
	// Badge,
	// Tooltip,
	Button,
} from "@mui/material";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
	StyledTableContainer,
	TableCellNoPadding,
} from "../../components/ui/Table";
// import { orderBy } from "lodash";
import { StyledTableCellBody, StyledTableCellHeader } from "../../components/ui/Table";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LoadingBox } from "../../components/ui/Box";
import Moment from "moment";
import { GetDailySummary } from "../../services/dailySummary.services";
// import { groupByDate } from "../../utils/groupeBy";
import CircularProgress from "@mui/material/CircularProgress";
import { formatCurrency } from "../../utils/utilityFunctions";
import "../../assets/style.css";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TableSelection from '@pxlwidgets/table-selection';
import ExcelExport from "../../components/ExcelExport";
import { VerticalAlignMiddleOutlined, ColumnHeightOutlined } from "@ant-design/icons";

import { TextButton } from "../../components/ui/Buttons";
function TableData(props) {
	const [data, setData] = useState(props.data);
	const [open, setOpen] = useState(props.open);
	// const [openPurchase, setOpenPurchase] = useState(false);

	useEffect(() => {
		setData(props.data);
		setOpen(props.open);
		// eslint-disable-next-line
	}, [props.dailySummary, props.open]);
	return (
		<React.Fragment key={props.index}>
			{data.rows.length >= 1 ?
				<>
					<TableRow hover onClick={() => data.rows.length >= 2 ? setOpen(!open) : ''}>
						<StyledTableCellBody>
							<Stack direction="row" justifyContent="space-between" alignItems="center">
								{Moment(data.date).format("MMM D, y")}
								{data.rows.length >= 2 ? (
									<>
										{open ? <KeyboardArrowDownIcon sx={{ mr: 5, color: '#2051BF' }} /> : <KeyboardArrowRightIcon sx={{ mr: 5, color: '#2051BF' }} />}
									</>
								) : (
									<></>
								)}
							</Stack>
						</StyledTableCellBody>
						{data.rows
							.filter((row) => row.description.includes("Carry Forward"))
							.map((row, index) => (
								<React.Fragment key={index}>
									<StyledTableCellBody
										align="right"
										variant="body2"
									></StyledTableCellBody>
									<StyledTableCellBody
										align="right"
										variant="body2"
										sx={{ color: row.availableBalance <= 0 ? "" : "#00AD47" }}
									>
										<Typography variant={row.availableBalance <= 0 ? "amountsFont" : "amountsFontBold"}>
											{formatCurrency(row.availableBalance)}
										</Typography>
									</StyledTableCellBody>
									<StyledTableCellBody
										align="right"
										variant="body2"
									></StyledTableCellBody>
									<StyledTableCellBody align="right" variant="body2">
										<Typography variant="amountsFont">
											{formatCurrency(row.receivableAmount)}
										</Typography>
									</StyledTableCellBody>

									<StyledTableCellBody align="right" variant="body2">
										<Typography variant="amountsFont">
											{formatCurrency(row.marketPlaceBalance)}
										</Typography>
									</StyledTableCellBody>
								</React.Fragment>
							))}
					</TableRow>
					<TableRow>
						<TableCellNoPadding style={{ padding: 0 }} colSpan={6}>
							<Collapse in={data.rows.length >= 2 ? open : false} timeout="auto" unmountOnExit>
								<Box sx={{ background: "#F1F5FC" }}>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCellNoPadding style={{ width: "19%" }}>
												</TableCellNoPadding>
												<TableCellNoPadding style={{ width: "19%" }}>
												</TableCellNoPadding>
												<TableCellNoPadding style={{ width: "19%" }}>
												</TableCellNoPadding>
												<TableCellNoPadding style={{ width: "5%" }}>
												</TableCellNoPadding>
												<TableCellNoPadding style={{ width: "19%" }}>
												</TableCellNoPadding>
												<TableCellNoPadding style={{ width: "19%" }}>
												</TableCellNoPadding>
											</TableRow>
										</TableHead>
										<TableBody>
											{data.rows
												.filter((row) => row.description.trim() === "Net Sales")
												.map((row, index) => (
													<React.Fragment key={index}>
														<TableRow >
															<TableCellNoPadding
																component="th"
																scope="row"
																variant="body2"
															>
																<a
																	href={`/transaction-breakdown?transactionType=${encodeURIComponent("Net Sales")}&requestdate=${encodeURIComponent(Moment(data.date).format(
																		"YYYY-MM-DD"
																	))}`}
																	target="_blank"
																	rel="noopener noreferrer"
																	className="linkText"
																>
																	Invoice Funded
																</a>

																{/* <IconButton
														aria-label="expand row"
														variant="text"
														onClick={() => setOpenPurchase(!openPurchase)}
														sx={{ padding: 0 }}
													>
														{openPurchase ? (
															<KeyboardArrowDownIcon sx={{ fontSize: "1.2rem" }} />
														) : (
															<KeyboardArrowRightIcon sx={{ fontSize: "1.2rem" }} />
														)}
													</IconButton> */}
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.marketPlaceBalance)}
																</Typography>
															</TableCellNoPadding>

															<TableCellNoPadding
																sx={{ color: "neutral.Gray_Dark" }}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.availableBalance)}
																</Typography>
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															>
																70%
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{ color: "neutral.Gray_Dark" }}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.receivableAmount)}
																</Typography>
															</TableCellNoPadding>

															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.marketPlaceBalance)}
																</Typography>
															</TableCellNoPadding>
														</TableRow>
														<TableRow sx={{ height: 10 }}></TableRow>
													</React.Fragment>
												))}
											{/* {data.rows
									.filter((row) =>
										row.description.trim() === "Net Sales - Receivable")
									.map((row, index) => (
										<React.Fragment key={index}>
											<TableRow>
												<TableCellNoPadding
													variant="body2"
													colSpan={4}
												>
													<a
														href={`/transaction-breakdown?transactionType=${encodeURIComponent("Net Sales - Receivable")}&requestdate=${encodeURIComponent(Moment(data.date).format(
															"YYYY-MM-DD"
														))}`}
														target="_blank"
														rel="noopener noreferrer"
														className="linkText"
													>
														Receivable Balance
													</a>
												</TableCellNoPadding>
												<TableCellNoPadding
													sx={{
														color: "neutral.Gray_Dark",
													}}
													align="right"
												>
													<Typography variant="amountsFont">
														{formatCurrency(row.availableBalance)}
													</Typography>
												</TableCellNoPadding>
											</TableRow>
											<TableRow sx={{ height: 10 }}></TableRow>
										</React.Fragment>
									))} */}
											{data.rows
												.filter((row) =>
													row.description.trim() === "Net Sales - Vepay Fee")
												.map((row, index) => (
													<React.Fragment key={index}>
														<TableRow>
															<TableCellNoPadding
																variant="body2"
																
															>
																<a
																	href={`/reports?transactionType=${encodeURIComponent("Net Sales - Vepay Fee")}&requestdate=${encodeURIComponent(Moment(data.date).format(
																		"YYYY-MM-DD"
																	))}&tabNo=2`}
																	target="_blank"
																	rel="noopener noreferrer"
																	className="linkText"
																>
																	VePay Fee
																</a>
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.availableBalance)}
																</Typography>
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
																colSpan={3}
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.availableBalance)}
																</Typography>
															</TableCellNoPadding>
														</TableRow>
														<TableRow sx={{ height: 10 }}></TableRow>
													</React.Fragment>
												))}
											{/* <React.Fragment>
									<TableRow sx={{ background: openPurchase ? "#ededed" : "" }}>
										<TableCellNoPadding style={{ padding: 0 }} colSpan={5}>
											<Collapse in={openPurchase} timeout="auto" unmountOnExit>
												<Box
													sx={{
														margin: "0 -0.6em 0 0",
														padding: "1em 0.4em 1em 1em",
													}}
												>
													<Table size="small" aria-label="purchases">
														<TableBody>
															 Purchase Received
															<TableRow sx={{ height: 10 }}></TableRow>
															VepayFee
														</TableBody>
													</Table>
												</Box>
											</Collapse>
										</TableCellNoPadding>
										<TableRow sx={{ height: 5 }}></TableRow>
									</TableRow>
								</React.Fragment> */}
											{data.rows
												.filter((row) => row.description.trim() === "Refunds")
												.map((row, index) => (
													<React.Fragment key={index}>
														<TableRow>
															<TableCellNoPadding
																component="th"
																scope="row"
																variant="body2"
															>
																<a
																	href={`/transaction-breakdown?transactionType=${encodeURIComponent("Refunds")}&requestdate=${encodeURIComponent(Moment(data.date).format(
																		"YYYY-MM-DD"
																	))}`}
																	target="_blank"
																	rel="noopener noreferrer"
																	className="linkText"
																>
																	Refunds
																</a>
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.totalPurchasedBalance)}
																</Typography>
															</TableCellNoPadding>

															<TableCellNoPadding
																sx={{ color: "neutral.Gray_Dark" }}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.availableBalance)}
																</Typography>
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															></TableCellNoPadding>
															<TableCellNoPadding
																sx={{ color: "neutral.Gray_Dark" }}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.receivableAmount)}
																</Typography>
															</TableCellNoPadding>

															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.marketPlaceBalance)}
																</Typography>
															</TableCellNoPadding>
														</TableRow>
														<TableRow sx={{ height: 10 }}></TableRow>
													</React.Fragment>
												))}
											{data.rows
												.filter((row) =>
													row.description.includes("Selling platform")
												)
												.map((row, index) => (
													<React.Fragment key={index}>
														<TableRow>
															<TableCellNoPadding
																component="th"
																scope="row"
																variant="body2"
															>
																<a
																	href={`/transaction-breakdown?transactionType=${encodeURIComponent("Selling platform")}&requestdate=${encodeURIComponent(Moment(data.date).format(
																		"YYYY-MM-DD"
																	))}`}
																	target="_blank"
																	rel="noopener noreferrer"
																	className="linkText"
																>
																	Marketplace Charges
																</a>
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.marketPlaceBalance)}
																</Typography>
															</TableCellNoPadding>

															<TableCellNoPadding
																sx={{ color: "neutral.Gray_Dark" }}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.availableBalance)}
																</Typography>
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															></TableCellNoPadding>
															<TableCellNoPadding
																sx={{ color: "neutral.Gray_Dark" }}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.receivableAmount)}
																</Typography>
															</TableCellNoPadding>

															<TableCellNoPadding
																sx={{ color: "neutral.Gray_Dark" }}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.marketPlaceBalance)}
																</Typography>
															</TableCellNoPadding>
														</TableRow>
														<TableRow sx={{ height: 10 }}></TableRow>
													</React.Fragment>
												))}
											{data.rows
												.filter((row) =>
													row.description.includes("Marketplace Payment")
												)
												.map((row, index) => (
													<React.Fragment key={index}>
														<TableRow>
															<TableCellNoPadding
																component="th"
																scope="row"
																variant="body2"
															>
																<a
																	href={`/reports?transactionType=${encodeURIComponent("Marketplace Payment")}&requestdate=${encodeURIComponent(Moment(data.date).format(
																		"YYYY-MM-DD"
																	))}&tabNo=3`}
																	target="_blank"
																	rel="noopener noreferrer"
																	className="linkText"
																>
																	Marketplace Payment
																</a>
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.marketPlaceBalance)}
																</Typography>
															</TableCellNoPadding>

															<TableCellNoPadding
																sx={{ color: "neutral.Gray_Dark" }}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.availableBalance)}
																</Typography>
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															></TableCellNoPadding>
															<TableCellNoPadding
																sx={{ color: "neutral.Gray_Dark" }}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.receivableAmount)}
																</Typography>
															</TableCellNoPadding>

															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.marketPlaceBalance)}
																</Typography>
															</TableCellNoPadding>
														</TableRow>
														<TableRow sx={{ height: 10 }}></TableRow>
													</React.Fragment>
												))}
											{data.rows
												.filter((row) => row.description.includes("Withdrawals"))
												.map((row, index) => (
													<React.Fragment key={index}>
														{(() => {
															if (!row.receivableAmount && !row.marketPlaceBalance)
																return null;
														})()}
														<TableRow>
															<TableCellNoPadding
																variant="body2"
															>
																<a
																	href={`/withdrawals?transactionType=${encodeURIComponent("Withdrawals")}&requestdate=${encodeURIComponent(Moment(data.date).format(
																		"YYYY-MM-DD"
																	))}`}
																	target="_blank"
																	rel="noopener noreferrer"
																	className="linkText"
																>
																	Withdrawals
																</a>
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.availableBalance)}
																</Typography>
															</TableCellNoPadding>

															<TableCellNoPadding
																sx={{ color: "neutral.Gray_Dark" }}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.availableBalance)}
																</Typography>
															</TableCellNoPadding>
															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															></TableCellNoPadding>
															<TableCellNoPadding
																sx={{ color: "neutral.Gray_Dark" }}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.receivableAmount)}
																</Typography>
															</TableCellNoPadding>

															<TableCellNoPadding
																sx={{
																	color: "neutral.Gray_Dark",
																}}
																align="right"
															>
																<Typography variant="amountsFont">
																	{formatCurrency(row.marketPlaceBalance)}
																</Typography>
															</TableCellNoPadding>
														</TableRow>
														<TableRow sx={{ height: 10 }}></TableRow>
													</React.Fragment>

												))}
										</TableBody>
									</Table>
								</Box>
							</Collapse>
						</TableCellNoPadding>
					</TableRow>
				</>
				:
				<>
				</>}

		</React.Fragment>
	);
}

export default function DailySummary() {
	const [dailySummary, setDailySummary] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [paginationDate, setPaginationDate] = useState(Moment().format('YYYY-MM-DD'));
	const [previousDate, setPreviosDate] = useState([]);
	const [expandAll, setExpandAll] = useState(false);
	const hasRun = useRef(false);

	useEffect(() => {
		if (hasRun.current) return;
		hasRun.current = true;

		const fetchData = async () => {
			setIsLoading(false);
			try {
				await GetDailySummary(paginationDate).then((data) => {
					const cleanedData = removeEmptyRows(data);
					const summary = Object.keys(cleanedData).map((date) => ({
						date,
						rows: Object.keys(cleanedData[date] || {}).map((desc) => ({
							description: desc,
							totalPurchasedBalance: cleanedData[date][desc][0],
							marketPlaceBalance: cleanedData[date][desc][1],
							availableBalance: cleanedData[date][desc][2],
							receivableAmount: cleanedData[date][desc][3],
						})),
					}));
					setIsLoading(true);
					setDailySummary(summary);
				});
				setIsLoading(false);
			} catch (error) {
				throw error;
			}
		};

		fetchData();
	}, [paginationDate]);

	if (!dailySummary)
		return (
			<LoadingBox>
				<CircularProgress />
			</LoadingBox>
		);

	function removeEmptyRows(data) {
		for (const date in data) {
			const properties = Object.keys(data[date]);
			for (const property of properties) {
				const amounts = data[date][property];
				const isEmptyRow = amounts.every(amount => amount === null || amount === 0);
				if (isEmptyRow) {
					delete data[date][property];
				}
			}
		}
		return data;
	}

	// var totalMarketplaceBalance = orderBy(
	// 	dailySummary,
	// 	"date",
	// 	"desc"
	// )[0]?.rows.find((row) =>
	// 	row.description.includes("Carry Forward")
	// ).marketPlaceBalance;

	// var totalReceivableAmount = orderBy(
	// 	dailySummary,
	// 	"date",
	// 	"desc"
	// )[0]?.rows.find((row) =>
	// 	row.description.includes("Carry Forward")
	// ).receivableAmount;

	// var totalAvailableBalance = orderBy(
	// 	dailySummary,
	// 	"date",
	// 	"desc"
	// )[0]?.rows.find((row) =>
	// 	row.description.includes("Carry Forward")
	// ).availableBalance;

	const handlePaginationDate = () => {
		hasRun.current = false;
		const nextDate = Moment(dailySummary[dailySummary.length - 1].date).subtract(1, 'days').calendar();
		setPaginationDate(Moment(nextDate).format('YYYY-MM-DD'))
		if (!previousDate.includes(dailySummary[0].date)) {
			// If it doesn't exist, add it
			setPreviosDate([...previousDate, dailySummary[0].date]);
		}
	}

	const handlePreviousDate = () => {
		hasRun.current = false;
		setPaginationDate(previousDate[previousDate.length - 1])
		if (previousDate.length > 0) {
			// Create a new array with the last item removed
			setPreviosDate(previousDate.slice(0, previousDate.length - 1));
		}
	}

	const tableSelect = new TableSelection({ selector: 'table' });

	const exportData = dailySummary
		.sort((a, b) => parseFloat(b.date) - parseFloat(a.date))
		.map((data) => ({
			"Date": Moment(data.date).format("MMM D, y"),
			"Available Balance ($)": Number(data.rows[0]?.availableBalance?.toFixed(2)),
			"Receivable Balance ($)": Number(data.rows[0]?.receivableAmount?.toFixed(2)),
			"Marketplace Balance ($)": Number(data.rows[0]?.marketPlaceBalance?.toFixed(2)),
		}));

	return (
		<div>
			<WithdrawCard />
			{/* <Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<Box
						sx={{
							backgroundColor: "#FFFFFF",
							boxShadow: "0px 0px 4px rgba(3, 40, 120, 0.18)",
							width: "100%",
						}}
					>
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCellSummary align="left" variant="body2">
										<Typography variant="h5" color="primary.Primary_Blue">
											Available Balance &nbsp;
											<Badge
												badgeContent={
													<Tooltip
														disableFocusListener
														disableTouchListener
														title="Current funds ready for withdrawal."
													>
														<InfoOutlinedIcon
															sx={{
																color: "#989898",
																marginLeft: "100%",
															}}
															fontSize="small"
														/>
													</Tooltip>
												}
											/>
										</Typography>
									</TableCellSummary>
								</TableRow>
								<TableRow>
									<TableCellSummary align="left" variant="body2">
										<Typography variant="body1" color="neutral.letter_Black">
											Amazon
										</Typography>
									</TableCellSummary>

									<TableCellSummary align="right" variant="body2">
										<Typography
											variant="amountsFont"
											color="neutral.letter_Black"
										>
											{formatCurrency(totalAvailableBalance, true)}
										</Typography>
									</TableCellSummary>
								</TableRow>
								<TableRow>
									<TableCellSummary
										align="left"
										variant="body2"
									></TableCellSummary>
								</TableRow>
								<TableRow style={{ background: "#4471D3" }}>
									<TableCellSummary align="left" variant="body2">
										<Typography variant="body1" color="neutral.Pure_White_Bg">
											Total Balance
										</Typography>
									</TableCellSummary>

									<TableCellSummary align="right" variant="body2">
										<Typography
											variant="amountsFont"
											color="neutral.Pure_White_Bg"
										>
											{formatCurrency(totalAvailableBalance, true)}
										</Typography>
									</TableCellSummary>
								</TableRow>
							</TableBody>
						</Table>
					</Box>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box
						sx={{
							backgroundColor: "#FFFFFF",
							boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
							width: "100%",
						}}
					>
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCellSummary align="left" variant="body2">
										<Typography variant="h5" color="primary.Primary_Blue">
											Receivable Balance &nbsp;
											<Badge
												badgeContent={
													<Tooltip
														disableFocusListener
														disableTouchListener
														title="Total net balance receivable after marketplace and VePay Fee deductions. The remaining balance consist of Agancy fee and Incentive fee receivable to the client."
													>
														<InfoOutlinedIcon
															sx={{
																color: "#989898",
																marginLeft: "100%",
															}}
															fontSize="small"
														/>
													</Tooltip>
												}
											/>
										</Typography>
									</TableCellSummary>
								</TableRow>
								<TableRow>
									<TableCellSummary align="left" variant="body2">
										<Typography variant="body1" color="neutral.letter_Black">
											Amazon
										</Typography>
									</TableCellSummary>

									<TableCellSummary align="right" variant="body2">
										<Typography
											variant="amountsFont"
											color="neutral.letter_Black"
										>
											{formatCurrency(totalReceivableAmount, true)}
										</Typography>
									</TableCellSummary>
								</TableRow>
								<TableRow>
									<TableCellSummary
										align="left"
										variant="body2"
									></TableCellSummary>
								</TableRow>
								<TableRow style={{ background: "#4471D3" }}>
									<TableCellSummary align="left" variant="body2">
										<Typography variant="body1" color="neutral.Pure_White_Bg">
											Total Balance
										</Typography>
									</TableCellSummary>

									<TableCellSummary align="right" variant="body2">
										<Typography
											variant="amountsFont"
											color="neutral.Pure_White_Bg"
										>
											{formatCurrency(totalReceivableAmount, true)}
										</Typography>
									</TableCellSummary>
								</TableRow>
							</TableBody>
						</Table>
					</Box>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box
						sx={{
							backgroundColor: "#FFFFFF",
							boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
							width: "100%",
						}}
					>
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCellSummary align="left" variant="body2">
										<Typography variant="h5" color="primary.Primary_Blue">
											Marketplace Balance &nbsp;
											<Badge
												badgeContent={
													<Tooltip
														disableFocusListener
														disableTouchListener
														title="Total funds receivable from the Marketplaces post-deduction."
													>
														<InfoOutlinedIcon
															sx={{
																color: "#989898",
																marginLeft: "100%",
															}}
															fontSize="small"
														/>
													</Tooltip>
												}
											/>
										</Typography>
									</TableCellSummary>
								</TableRow>
								<TableRow>
									<TableCellSummary align="left" variant="body2">
										<Typography variant="body1" color="neutral.letter_Black">
											Amazon
										</Typography>
									</TableCellSummary>

									<TableCellSummary align="right" variant="body2">
										<Typography
											variant="amountsFont"
											color="neutral.letter_Black"
										>
											{formatCurrency(totalMarketplaceBalance, true)}
										</Typography>
									</TableCellSummary>
								</TableRow>
								<TableRow>
									<TableCellSummary
										align="left"
										variant="body2"
									></TableCellSummary>
								</TableRow>
								<TableRow style={{ background: "#4471D3" }}>
									<TableCellSummary align="left" variant="body2">
										<Typography variant="body1" color="neutral.Pure_White_Bg">
											Total Balance
										</Typography>
									</TableCellSummary>

									<TableCellSummary align="right" variant="body2">
										<Typography
											variant="amountsFont"
											color="neutral.Pure_White_Bg"
										>
											{formatCurrency(totalMarketplaceBalance, true)}
										</Typography>
									</TableCellSummary>
								</TableRow>
							</TableBody>
						</Table>
					</Box>
				</Grid>
			</Grid> */}
			<Box
				sx={{
					mt: 2,
					p: 3,
					backgroundColor: "#FFFFFF",
					boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
				}}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography variant="h4">Daily Transaction Summary</Typography>
					<Stack direction="row">
						<TextButton variant="text" onClick={() => setExpandAll(!expandAll)}>{expandAll ? <VerticalAlignMiddleOutlined style={{ fontSize: '1.5rem' }} /> : <ColumnHeightOutlined style={{ fontSize: '1.5rem' }} />}</TextButton>
						<ExcelExport
							filename={"Daily Transaction Summary"}
							excelData={exportData}
						/>
					</Stack>
				</Stack>
				<br></br>
				<StyledTableContainer>
					<Table
						sx={{ minWidth: 650 }}
						aria-label="simple table"
						className={tableSelect.className}
					>
						<TableHead>
							<TableRow sx={{ backgroundColor: "#F6F6F6" }}>
								<StyledTableCellHeader align="left" style={{ width: "19%" }}>Date</StyledTableCellHeader>
								<StyledTableCellHeader align="right" style={{ width: "19%" }}>
									Total Amount
								</StyledTableCellHeader>
								<StyledTableCellHeader align="right" style={{ width: "19%" }}>
									Available Balance
								</StyledTableCellHeader>
								<StyledTableCellHeader align="right" style={{ width: "5%" }}>
									%
								</StyledTableCellHeader>
								<StyledTableCellHeader align="right" style={{ width: "19%" }}>
									Receivable Balance
								</StyledTableCellHeader>
								<StyledTableCellHeader align="right" style={{ width: "19%" }}>
									Marketplace Balance
								</StyledTableCellHeader>
							</TableRow>
						</TableHead>
						<TableBody>
							{isLoading ? (
								<LoadingBox>
									<CircularProgress />
								</LoadingBox>
							) : (
								<>
									{dailySummary.length === 0 ? (
										<TableRow>
											<StyledTableCellBody
												colSpan={8}
												align="center"
												sx={{ padding: "10%" }}
											>
												<Typography variant="h5">
													<b>No results found</b>
												</Typography>
											</StyledTableCellBody>
										</TableRow>
									) : (dailySummary
										.sort(
											(a, b) =>
												parseFloat(Moment(b.date).format("MMDDY")) -
												parseFloat(Moment(a.date).format("MMDDY"))
										)
										.map((data, index) => (
											<React.Fragment key={index}>
												<TableData
													open={expandAll}
													key={index}
													data={data}
													index={index}
													dailySummary={dailySummary}
												/>
											</React.Fragment>
										)))}
								</>
							)}

						</TableBody>
					</Table>
				</StyledTableContainer>
				<br></br>
				<Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
					<Button variant="outlined" disabled={previousDate.length <= 0} onClick={() => handlePreviousDate()}>
						<ChevronLeftIcon />
					</Button>
					<Button variant="outlined" disabled={dailySummary.length < 9} onClick={() => handlePaginationDate()}>
						<NavigateNextIcon />
					</Button>
				</Stack>
			</Box>
			<br></br>
		</div>
	);
}
