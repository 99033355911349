import React from "react";
import { useState } from "react";
import WithdrawCard from "../../components/Withdraw";
import Transactions from "./Transactions";
import VepayFee from "./VepayFee";
import MarketplacePayments from "./MarketplacePayments";
import { StyledTabs, StyledTab } from "../../components/ui/Tabs";
import { Box } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { useLocation } from "react-router-dom";
// import GenerateReport from "./GenerateReport";

export default function Reports() {
  let data = useLocation();

  const queryParams = new URLSearchParams(data.search);
	const tabNo = queryParams.get('tabNo');
  const propsData = {transactionType: queryParams.get('transactionType'), requestDate: queryParams.get('requestdate')}

  const [value, setValue] = useState(tabNo ? tabNo : "1");


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <WithdrawCard />
      <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
          <Box>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <StyledTab label="Transactions" value="1" />
              <StyledTab label="VePay Fee" value="2" />
              <StyledTab label="Marketplace Payment" value="3" />
              {/* <StyledTab label="Generate Report" value="4" /> */}
            </StyledTabs>
            <Box sx={{ p: 1 }} />
          </Box>
          <TabPanel value="1" sx={{ padding: 1 }}>
            <Transactions props={propsData || false}/>
          </TabPanel>
          <TabPanel value="2" sx={{ padding: 1 }}>
            <VepayFee props={propsData || false}/>
          </TabPanel>
          <TabPanel value="3" sx={{ padding: 1 }}>
            <MarketplacePayments props={propsData || false}/>
          </TabPanel>
          {/* <TabPanel value="4" sx={{ padding: 1 }}>
            <GenerateReport />
          </TabPanel> */}
        </TabContext>
      </Box>
    </div>
  );
}
