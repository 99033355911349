import React from "react";
import Withdraw from "../../components/Withdraw";
import { useState, useEffect, useRef } from "react";
import {
	Typography,
	Stack,
	Box,
	Table,
	TableBody,
	TableRow,
	TableHead,
	TablePagination,
	TextField,
	Popover,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button
} from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { StyledTableCellBody, StyledTableContainer, StyledTableCellHeader } from "../../components/ui/Table";
import { LoadingBox } from "../../components/ui/Box";
// import { StyledTextBox } from "../../components/TextBox";
import { LightBlueContainedButton, TextButton } from "../../components/ui/Buttons";
import { YellowBox, GreenBox, RedBox, BlueBox } from "../../components/ui/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Moment from "moment";
import { formatCurrency } from "../../utils/utilityFunctions";
import { groupByDateWithdrawals } from "../../utils/groupeBy";
import { FilterAlt } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import TableSelection from '@pxlwidgets/table-selection';
import ExcelExport from "../../components/ExcelExport";
import { GetTransfers } from "../../services/withdrawal.services";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function Withdrawals() {
	let data = useLocation();
	const queryParams = new URLSearchParams(data.search);

	let reportStartDate = moment().subtract(365, "days").toDate();
	let reportEndDate = moment().toDate();

	const [withdrawals, setWithdrawals] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [chartData, setChartData] = useState(null)
	const [filter, setFilter] = useState(null); //state of the filter
	const [startDate, setStartDate] = useState(queryParams.get('requestdate') || reportStartDate);
	const [endDate, setEndDate] = useState(queryParams.get('requestdate') || reportEndDate);
	const [startDateError, setStartDateError] = useState(false);
	const [endDateError, setEndDateError] = useState(false);
	const [value, setValue] = useState([startDate, endDate]); //state of date picker in filter
	const [openNoteModal, setOpenNoteModal] = useState(false);
	const [modalNote, setModalNote] = useState('');

	const hasRun = useRef(false);

	useEffect(() => {
		if (hasRun.current) return;
		hasRun.current = true;

		const fetchData = async () => {
			setIsLoading(false);
			try {
				await GetTransfers(value[0], value[1]).then((data) => {
					setIsLoading(true);
					setWithdrawals(data);
					setChartData(groupByDateWithdrawals(data));
				});
				setIsLoading(false);
			} catch (error) {
				throw error;
			}
		};

		fetchData();
	}, [value]);

	if (!withdrawals || !chartData)
		return (
			<LoadingBox>
				<CircularProgress />
			</LoadingBox>
		);

	const handleFilter = () => {
		hasRun.current = false;
		setValue([startDate, endDate]);
		handleCloseMP();
	};

	const handlerFilterReset = () => {
		hasRun.current = false;
		setStartDate(moment().subtract(7, "days").toDate());
		setEndDate(new Date());
		handleCloseMP();
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const chartOptions = {
		title: {
			text: "Withdrawal History",
			align: "left",
		},
		chart: {
			type: "column",
			// width: 1300,
		},
		plotOptions: {
			column: {
				stacking: "normal",
			},
		},
		colors: ["#0096DA"],
		xAxis: {
			categories:
				chartData.sort(
					(a, b) =>
						parseFloat(Moment(a.createdDate).format("MMDDY")) -
						parseFloat(Moment(b.createdDate).format("MMDDY"))
				)
					.map((data) => {
						return Moment(data.createdDate).format("MMM, y");
					}),
			crosshair: true,
		},
		yAxis: {
			title: {
				text: "Withdrawal  Amount (USD)",
			},
		},
		tooltip: {
			pointFormat: '{series.name}: <b>{point.y:.2f}</b><br/>',
		},
		series: [
			{
				name: "Total ($)",
				data: chartData.sort(
					(a, b) =>
						parseFloat(Moment(a.createdDate).format("MMDDY")) -
						parseFloat(Moment(b.createdDate).format("MMDDY"))
				)
					.map((data) => {
						return data.transferAmountTotal;
					}),
			},
		],
		credits: {
			enabled: false,
		}, // The water mark removal place
	};

	const handleClickMP = (event) => {
		setFilter(event.currentTarget);
	};

	const handleCloseMP = () => {
		setFilter(null);
	};

	const openMP = Boolean(filter);
	const idMP = openMP ? "simple-popover" : undefined;

	const tableSelect = new TableSelection({ selector: 'table' });

	const exportData = withdrawals
		.map((row) => ({
			"Transfer Request Date": moment(row.transferRequestDate).format("MMM D, y"),
			"Request Amount ($)": Number(row.requestAmount?.toFixed(2)),
			"Transfer Fee ($)": Number(row.transferFee?.toFixed(2)),
			"Final Transfer Amount ($)": Number(row.finalTransferAmount?.toFixed(2)),
			"Transfer Method": row.transferMethod,
			"Bank Account": row.bankAccountNumber,
			"Request Status": row.requestStatus,
			"Transfer Completed Date": moment(row.transferDate).format("MMM D, y"),
			"Transfer Adjustment or Cancellation Reason": row.note,
		}));

	const handleNoteModalOpen = (value) => {
		setModalNote(value);
		setOpenNoteModal(true);
	};

	const handleNoteModalClose = () => {
		setModalNote('');
		setOpenNoteModal(false);
	};

	return (
		<div>
			<Withdraw />
			<br></br>
			<Box
				sx={{
					p: 3,
					backgroundColor: "#FFFFFF",
					boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
				}}
			>
				<HighchartsReact options={chartOptions} highcharts={Highcharts} />
			</Box>
			<br></br>
			<Box
				sx={{
					p: 3,
					backgroundColor: "#FFFFFF",
					boxShadow: "0px 2px 8px rgba(3, 40, 120, 0.18)",
				}}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Stack direction="row" alignItems="center" spacing={2}>
						<Typography variant="h4">Withdrawal Details</Typography>
						<Typography variant="body2">
							Time period {moment(value[0]).format("MMM D, y")} -&nbsp;
							{moment(value[1]).format("MMM D, y")}
						</Typography>
					</Stack>
					<Stack direction="row" alignItems="center" spacing={2}>
						<TextButton variant="text" onClick={handleClickMP}>
							<FilterAlt />
						</TextButton>
						<ExcelExport
							filename={
								`Withdrawal Report from ${moment(value[0]).format("MMM D, y")} to ${moment(value[1]).format("MMM D, y")}`
							}
							excelData={exportData}
						/>
					</Stack>
				</Stack>
				<Popover
					id={idMP}
					open={openMP}
					anchorEl={filter}
					onClose={handleCloseMP}
					PaperProps={{
						style: {
							padding: "1% 2% 2% 2%",
							width: "25%",
							borderRadius: "10px",
						},
					}}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						spacing={2}
						alignItems="center"
					>
						<Typography sx={{ p: 2 }} variant="h5">
							<b>Filter Report</b>
						</Typography>
						<CloseIcon onClick={handleCloseMP} />
					</Stack>
					<br></br>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<div>
							<Stack sx={{ width: "100%" }} spacing={2}>
								<DatePicker
									openTo="day"
									views={["year", "month", "day"]}
									label="Start"
									value={startDate}
									maxDate={endDate}
									onChange={(newValue) => {
										setStartDate(newValue);
									}}
									renderInput={(params) => (
										<>
											{setStartDateError(params.error)}
											<TextField {...params} helperText={null} />
										</>
									)}
								/>
								<DatePicker
									openTo="day"
									views={["year", "month", "day"]}
									label="End"
									value={endDate}
									minDate={startDate}
									onChange={(newValue) => {
										setEndDate(newValue);
									}}
									renderInput={(params) => (
										<>
											{setEndDateError(params.error)}
											<TextField {...params} helperText={null} />
										</>
									)}
								/>
							</Stack>
						</div>
					</LocalizationProvider>
					<br></br>
					<br></br>
					<Stack direction="row" spacing={2}>
						{startDateError === false && endDateError === false ? (
							<LightBlueContainedButton
								fullWidth
								variant="outlined"

								onClick={handleFilter}
								size="large"
							>
								Filter
							</LightBlueContainedButton>
						) : (
							<LightBlueContainedButton fullWidth variant="outlined" disabled>
								Apply
							</LightBlueContainedButton>
						)}
						<LightBlueContainedButton
							fullWidth
							variant="contained"
							onClick={handlerFilterReset}
							size="large"
						>
							Reset
						</LightBlueContainedButton>
					</Stack>
				</Popover>
				<br></br>
				<StyledTableContainer >
					<Table sx={{ minWidth: 650 }} aria-label="simple table" className={tableSelect.className}>
						<TableHead>
							<TableRow sx={{ backgroundColor: "#F6F6F6" }}>
								<StyledTableCellHeader align="left">Transfer Request Date</StyledTableCellHeader>
								<StyledTableCellHeader align="right">
									Request Amount
								</StyledTableCellHeader>
								<StyledTableCellHeader align="right">Transfer&nbsp;Fee</StyledTableCellHeader>
								<StyledTableCellHeader align="right">Final Transfer Amount</StyledTableCellHeader>
								<StyledTableCellHeader align="center">
								Transfer&nbsp;Method
								</StyledTableCellHeader>
								<StyledTableCellHeader align="right">
									Bank&nbsp;Account
								</StyledTableCellHeader>
								<StyledTableCellHeader align="center">
									Request Status
								</StyledTableCellHeader>
								<StyledTableCellHeader align="left">
								Transfer Completed Date
								</StyledTableCellHeader>
								<StyledTableCellHeader align="center">
									View Note
								</StyledTableCellHeader>
							</TableRow>
						</TableHead>
						<TableBody>
							{isLoading ? (
								<LoadingBox>
									<CircularProgress />
								</LoadingBox>
							) : (
								<>
									{withdrawals.length === 0 ? (
										<TableRow>
											<StyledTableCellBody
												colSpan={9}
												align="center"
												sx={{ padding: "10%" }}
											>
												<Typography variant="h5">
													<b>No results found</b>
												</Typography>
												<Typography variant="h5_light">
													<b>From</b> {moment(value[0]).format("MMM D, y")} -&nbsp;<b>To</b>&nbsp;
													{moment(value[1]).format("MMM D, y")}
												</Typography>
											</StyledTableCellBody>
										</TableRow>
									) : (
										withdrawals
											.sort((a, b) => b.transferRequestDate - a.transferRequestDate)
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((data, index) => (
												<>
													<TableRow key={index}>
														<StyledTableCellBody align="left">
															{moment(data.transferRequestDate).format("MMM D, y")}
														</StyledTableCellBody>
														<StyledTableCellBody align="right">
															<Typography variant="amountsFont">
																{formatCurrency(data.requestAmount)}
															</Typography>
														</StyledTableCellBody>
														<StyledTableCellBody align="right">
															<Typography variant="amountsFont">
																{formatCurrency(data.transferFee)}
															</Typography>
														</StyledTableCellBody>
														<StyledTableCellBody align="right">
															<Typography variant="amountsFont">
																{formatCurrency(data.finalTransferAmount)}
															</Typography>
														</StyledTableCellBody>
														<StyledTableCellBody align="center">
															{data.transferMethod}
														</StyledTableCellBody>
														<StyledTableCellBody align="right">
															{data.bankAccountNumber}
														</StyledTableCellBody>
														<StyledTableCellBody align="center">
															<Stack alignItems="center">
																{(() => {
																	if (data.requestStatus === "REALISED") {
																		return (
																			<GreenBox>
																				<b>REALISED</b>
																			</GreenBox>
																		);
																	} else if (data.requestStatus === "REJECTED") {
																		return (
																			<RedBox>
																				<b>Declined</b>
																			</RedBox>
																		);
																	} else if (data.requestStatus === "OPEN") {
																		return (
																			<BlueBox>
																				<b>Pending</b>
																			</BlueBox>
																		);
																	} else {
																		return (
																			<YellowBox>
																				<b>Pending</b>
																			</YellowBox>
																		);
																	}
																})()}
															</Stack>
														</StyledTableCellBody>
														<StyledTableCellBody align="left">
															{/* {moment(data.transferDate).format("MMM D, y")} */}
														</StyledTableCellBody>
														<StyledTableCellBody align="center" variant="body2">
															<TextButton
																disabled={data.note ? false : true}
																variant="text"
																onClick={() => handleNoteModalOpen(data.note)}
															>
																<OpenInNewIcon />
															</TextButton>
														</StyledTableCellBody>
													</TableRow>
												</>
											))
									)}
								</>
							)}

						</TableBody>
					</Table>
				</StyledTableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={withdrawals.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>

				<Dialog
					open={openNoteModal}
					onClose={handleNoteModalClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						Transfer Adjustment or Cancellation Reason
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{modalNote}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleNoteModalClose} autoFocus>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
			<br></br>
		</div>
	);
}
