import React from "react";
import { useState, useEffect, useRef } from "react";
import {
	Typography,
	Stack,
	Box,
	Table,
	TableBody,
	TableRow,
	TableHead,
	TablePagination,
	TextField,
	Popover,
	Tooltip
} from "@mui/material";
import {
	StyledTableContainer,
} from "../../components/ui/Table";
// import { StyledTextBox } from "../../components/TextBox";
import { YellowBox, GreenBox, RedBox, BlueBox } from "../../components/ui/Chip";
import { LoadingBox } from "../../components/ui/Box";
import { StyledTableCellBody, StyledTableCellHeader } from "../../components/ui/Table";
// import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import CircularProgress from "@mui/material/CircularProgress";
import { GetTransactionBreakdown } from "../../services/reports.services";
import { formatCurrency, showLastSevenDigits } from "../../utils/utilityFunctions";
import moment from "moment";
import { LightBlueContainedButton, TextButton } from "../../components/ui/Buttons";
import { FilterAlt } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloseIcon from "@mui/icons-material/Close";
import TableSelection from '@pxlwidgets/table-selection';
import ExcelExport from "../../components/ExcelExport";
import { useLocation } from "react-router-dom";

export default function Transactions({ props }) {
	let data = useLocation();
	const queryParams = new URLSearchParams(data.search);

	let reportStartDate = moment().subtract(365, "days").toDate();
	let reportEndDate = moment().toDate();

	const [isLoading, setIsLoading] = useState(false);
	const [transactionReport, setTransactionReport] = useState(null);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [startDate, setStartDate] = useState(queryParams.get('requestdate') || reportStartDate);
	const [endDate, setEndDate] = useState(queryParams.get('requestdate') || reportEndDate);
	const [startDateError, setStartDateError] = useState(false);
	const [endDateError, setEndDateError] = useState(false);
	const [value, setValue] = useState([startDate, endDate]); //state of date picker in filter
	const [filter, setFilter] = useState(null); //state of the filter
	const hasRun = useRef(false);

	useEffect(() => {
		if (hasRun.current) return;
		hasRun.current = true;

		const fetchData = async () => {
			setIsLoading(false);
			try {
				await GetTransactionBreakdown("Withdrawals", value[0], value[1]).then((data) => {
					setIsLoading(true);
					setTransactionReport(data);
				});
				setIsLoading(false);
			} catch (error) {
				throw error;
			}
		};

		fetchData();
	}, [props, value]);

	if (!transactionReport)
		return (
			<LoadingBox>
				<CircularProgress />
			</LoadingBox>
		);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleFilter = () => {
		hasRun.current = false;
		setValue([startDate, endDate]);
		handleCloseMP();
	};

	const handlerFilterReset = () => {
		hasRun.current = false;
		setStartDate(moment().subtract(7, "days").toDate());
		setEndDate(new Date());
		handleCloseMP();
	};

	const handleClickMP = (event) => {
		setFilter(event.currentTarget);
	};

	const handleCloseMP = () => {
		setFilter(null);
	};

	const openMP = Boolean(filter);
	const idMP = openMP ? "simple-popover" : undefined;

	const tableSelect = new TableSelection({ selector: 'table' });

	const exportData = transactionReport
		.map((row) => ({
			"Date": moment(row.createdDate).format("MMM D, y"),
			"Transaction Type": 'Purchased Received',
			"Order ID": row.id,
			"Available Balance ($)": Number(row.amount?.toFixed(2)),
			"Receivable Balance ($)": Number(row.amount2?.toFixed(2)),
			"Marketplace Balance ($)": Number(row.amount3?.toFixed(2)),
			"Marketplace Payment Status": row.description,
		}));

	return (
		<div>
			<Box
				sx={{
					p: 3,
					backgroundColor: "#FFFFFF",
					boxShadow: "0px 0px 4px rgba(3, 40, 120, 0.18)",
					borderRadius: "2px",
				}}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="flex-end"
				>

					<Stack direction="row" alignItems="center" spacing={2}>
						<Typography variant="h4">Transaction Report</Typography>
						<Typography variant="body2">
							Time period {moment(value[0]).format("MMM D, y")} -&nbsp;
							{moment(value[1]).format("MMM D, y")}
						</Typography>
					</Stack>
					<Stack direction="row" alignItems="center" spacing={2}>
						<TextButton variant="text" onClick={handleClickMP}>
							<FilterAlt />
						</TextButton>
						<ExcelExport
							filename={
								`Transaction Report from ${moment(value[0]).format("MMM D, y")} to ${moment(value[1]).format("MMM D, y")}`
							}
							excelData={exportData}
						/>
					</Stack>
				</Stack>
				{/* <br></br> */}
				<Popover
					id={idMP}
					open={openMP}
					anchorEl={filter}
					onClose={handleCloseMP}
					PaperProps={{
						style: {
							padding: "1% 2% 2% 2%",
							width: "25%",
							borderRadius: "10px",
						},
					}}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						spacing={2}
						alignItems="center"
					>
						<Typography sx={{ p: 2 }} variant="h5">
							<b>Filter Report</b>
						</Typography>
						<CloseIcon onClick={handleCloseMP} />
					</Stack>
					<br></br>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<div>
							<Stack sx={{ width: "100%" }} spacing={2}>
								<DatePicker
									openTo="day"
									views={["year", "month", "day"]}
									label="Start"
									value={startDate}
									maxDate={endDate}
									onChange={(newValue) => {
										setStartDate(newValue);
									}}
									renderInput={(params) => (
										<>
											{setStartDateError(params.error)}
											<TextField {...params} helperText={null} />
										</>
									)}
								/>
								<DatePicker
									openTo="day"
									views={["year", "month", "day"]}
									label="End"
									value={endDate}
									minDate={startDate}
									onChange={(newValue) => {
										setEndDate(newValue);
									}}
									renderInput={(params) => (
										<>
											{setEndDateError(params.error)}
											<TextField {...params} helperText={null} />
										</>
									)}
								/>
							</Stack>
						</div>
					</LocalizationProvider>
					<br></br>
					<br></br>
					<Stack direction="row" spacing={2}>
						{startDateError === false && endDateError === false ? (
							<LightBlueContainedButton
								fullWidth
								variant="outlined"
								onClick={handleFilter}
								size="large"
							>
								Filter
							</LightBlueContainedButton>
						) : (
							<LightBlueContainedButton fullWidth variant="outlined" disabled>
								Apply
							</LightBlueContainedButton>
						)}
						<LightBlueContainedButton
							fullWidth
							variant="contained"
							onClick={handlerFilterReset}
							size="large"
						>
							Reset
						</LightBlueContainedButton>
					</Stack>
				</Popover>
				{/* <br></br> */}
				{/*  <Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					 <Stack direction="row" spacing={2}>
						<StyledTextBox variant="outlined" placeholder="Search" />
						<LightBlueContainedButton variant="contained">
							Since joining &nbsp; <KeyboardArrowDownOutlinedIcon />
						</LightBlueContainedButton>
						<LightBlueContainedButton variant="contained">
						 Filter
						</LightBlueContainedButton>
					</Stack> 
				</Stack>*/}
				<StyledTableContainer sx={{mt:3}}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table" className={tableSelect.className}>
						<TableHead>
							<TableRow sx={{ backgroundColor: "#F6F6F6" }}>
								<StyledTableCellHeader align="left">Date</StyledTableCellHeader>
								<StyledTableCellHeader align="left">
									Transaction Type
								</StyledTableCellHeader>
								<StyledTableCellHeader align="right">Order ID</StyledTableCellHeader>
								<StyledTableCellHeader align="right">Available Balance</StyledTableCellHeader>
								<StyledTableCellHeader align="right">
									Receivable Balance
								</StyledTableCellHeader>
								<StyledTableCellHeader align="right">
									Marketplace Balance
								</StyledTableCellHeader>
								<StyledTableCellHeader align="center">
									Payment Status
								</StyledTableCellHeader>
							</TableRow>
						</TableHead>
						<TableBody>
							{isLoading ? (
								<LoadingBox>
									<CircularProgress />
								</LoadingBox>
							) : (
								<>
									{transactionReport.length === 0 ? (
										<TableRow>
											<StyledTableCellBody
												colSpan={8}
												align="center"
												sx={{ padding: "10%" }}
											>
												<Typography variant="h5">
													<b>No results found</b>
												</Typography>
												<Typography variant="h5_light">
													<b>From</b> {moment(value[0]).format("MMM D, y")} -&nbsp;<b>To</b>&nbsp;
													{moment(value[1]).format("MMM D, y")}
												</Typography>
											</StyledTableCellBody>
										</TableRow>
									) : (
										transactionReport
											.sort((a, b) => b.id - a.id)
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((data, index) => (
												<>
													<TableRow hover key={index}>
														<StyledTableCellBody align="left">
															<Stack>
																<Typography variant="h5">
																	{moment(data.createdDate).format("MMM D, y")}
																</Typography>
																{/* <Typography variant="body1_light">
																	{moment(data.createdTime, "HH:mm:ss").format("h:mm A")}
																</Typography> */}
															</Stack>
														</StyledTableCellBody>
														<StyledTableCellBody align="lefft">
															Funded Amount
														</StyledTableCellBody>
														<StyledTableCellBody align="right">
														<Tooltip title={data.ref1?.replace("AMAZON+", "")} placement="left">
															<span>
																{showLastSevenDigits(data.ref1?.replace("AMAZON+", ""))}
															</span>
														</Tooltip>
														</StyledTableCellBody>
														<StyledTableCellBody align="right">
															<Typography variant="amountsFont">
																{formatCurrency(data.amount)}
															</Typography>
														</StyledTableCellBody>
														<StyledTableCellBody align="right">
															<Typography variant="amountsFont">
																{formatCurrency(data.amount2)}
															</Typography>
														</StyledTableCellBody>
														<StyledTableCellBody align="right">
															<Typography variant="amountsFont">
																{formatCurrency(data.amount3)}
															</Typography>
														</StyledTableCellBody>
														<StyledTableCellBody align="center">
															<Stack alignItems="center">
																{(() => {
																	if (data.description === "APPROVED") {
																		return (
																			<GreenBox>
																				<b>Success</b>
																			</GreenBox>
																		);
																	} else if (data.description === "REJECTED") {
																		return (
																			<RedBox>
																				<b>Declined</b>
																			</RedBox>
																		);
																	} else if (data.description === "EXPECTED") {
																		return (
																			<YellowBox>
																				<b>Expected</b>
																			</YellowBox>
																		);
																	} else {
																		return (
																			<BlueBox>
																				<b>Pending</b>
																			</BlueBox>
																		);
																	}
																})()}
															</Stack>
														</StyledTableCellBody>
													</TableRow>
												</>
											))
									)}
								</>
							)}

						</TableBody>
					</Table>
				</StyledTableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={transactionReport.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Box>
			<br></br>
		</div>
	);
}
