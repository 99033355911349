import React from "react";
import { useState, useEffect, useRef } from "react";
import {
	Typography,
	Stack,
	Box,
	Table,
	TableBody,
	TableRow,
	TableHead,
	// Collapse,
	IconButton,
	TablePagination,
	Tooltip,
	// Menu,
	// MenuItem,
	Popover,
	TextField
} from "@mui/material";
import {
	StyledTableContainer,
	// TableCellNoPadding,
} from "../../components/ui/Table";
// import { StyledTextBox } from "../../components/TextBox";
import { LightBlueContainedButton, TextButton } from "../../components/ui/Buttons";
import { YellowBox, GreenBox, RedBox, BlueBox } from "../../components/ui/Chip";
import { LoadingBox } from "../../components/ui/Box";
import { StyledTableCellBody, StyledTableCellHeader } from "../../components/ui/Table";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CircularProgress from "@mui/material/CircularProgress";
import { GetTransactionBreakdown } from "../../services/reports.services";
import { formatCurrency, showLastSevenDigits } from "../../utils/utilityFunctions";
// import moment from "moment";
import { useLocation, NavLink } from "react-router-dom";
import WithdrawCard from "../../components/Withdraw";
// import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import Moment from "moment";
import ExcelExport from "../../components/ExcelExport";
import TableSelection from '@pxlwidgets/table-selection';
import { FilterAlt } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloseIcon from "@mui/icons-material/Close";

export default function TransactionBreakdown() {
	let data = useLocation();
	const queryParams = new URLSearchParams(data.search);
	const requestDate = queryParams.get('requestdate');

	let reportStartDate = Moment().subtract(365, "days").toDate();
	let reportEndDate = Moment().toDate();

	// const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// const navigate = useNavigate();
	const [transactionBreakdown, setTransactionBreakdown] = useState(null);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [filter, setFilter] = useState(null); //state of the filter
	const [startDate, setStartDate] = useState(requestDate || reportStartDate);
	const [endDate, setEndDate] = useState(requestDate || reportEndDate);
	const [startDateError, setStartDateError] = useState(false);
	const [endDateError, setEndDateError] = useState(false);
	const [value, setValue] = useState([startDate, endDate]); //state of date picker in filter

	const hasRun = useRef(false);


	const transactionType = queryParams.get('transactionType');

	useEffect(() => {
		if (hasRun.current) return;
		hasRun.current = true;

		const fetchData = async () => {
			// if (data.state === null) {
			// 	navigate("/daily-summary");
			// }
			setIsLoading(false);
			try {
				await GetTransactionBreakdown(transactionType, value[0], value[1]).then(
					(data) => {
						setIsLoading(true);
						setTransactionBreakdown(data);
					}
				);
				setIsLoading(false);
			} catch (error) {
				throw error;
			}
		};

		fetchData();
	}, [transactionType, value]);

	if (!transactionBreakdown)
		return (
			<LoadingBox>
				<CircularProgress />
			</LoadingBox>
		);

	const exportData = transactionBreakdown
		.map((row) => ({
			"Date": requestDate,
			"Transaction Type": transactionType,
			"Order ID": row.ref1?.replace("AMAZON+", ""),
			"Avaialale Balance ($)": Number(row.amount2?.toFixed(2)),
			"Receivable Balance ($)": Number(row.amount3?.toFixed(2)),
			"Marketplace Balance ($)": Number(row.amount?.toFixed(2)),
			"Marketplace Payment Status": row.description,
		}));
	// const dates = data.state.marketplaceData.map((data) => {return data.requestdate})
	// const marketplacenames = data.state.marketplaceData.map((data) => {return data.childInvoices.map((childData) => childData.marketplaceslist.map((markplacedata) => markplacedata.marketplacename))})

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleFilter = () => {
		hasRun.current = false;
		setValue([startDate, endDate]);
		handleCloseMP();
	};

	const handlerFilterReset = () => {
		hasRun.current = false;
		setStartDate(Moment().subtract(7, "days").toDate());
		setEndDate(new Date());
		handleCloseMP();
	};

	const handleClickMP = (event) => {
		setFilter(event.currentTarget);
	};

	const handleCloseMP = () => {
		setFilter(null);
	};

	const openMP = Boolean(filter);
	const idMP = openMP ? "simple-popover" : undefined;

	const tableSelect = new TableSelection({ selector: 'table' });

	return (
		<div>
			<WithdrawCard />
			<br></br>
			<Box
				sx={{
					p: 3,
					backgroundColor: "#FFFFFF",
					boxShadow: "0px 0px 4px rgba(3, 40, 120, 0.18)",
					borderRadius: "2px",
				}}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="flex-end"
				>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<NavLink to="/daily-summary">
							<IconButton>
								<ArrowBackIosIcon sx={{ fontSize: 18 }} />
							</IconButton>
						</NavLink>
						<Stack direction="row" alignItems="center" spacing={2}>
						
						<Typography variant="h4">Transaction Breakdown</Typography>
						<Typography variant="body2">
							Time period {Moment(value[0]).format("MMM D, y")} -&nbsp;
							{Moment(value[1]).format("MMM D, y")}
						</Typography>
					</Stack>
					</Stack>
					<Stack direction="row" alignItems="center" spacing={2}>
						<TextButton variant="text" onClick={handleClickMP}>
							<FilterAlt />
						</TextButton>
						<ExcelExport
							filename={
								"Transaction Breakdown " + Moment(requestDate).format("MMM D, y")
							}
							excelData={exportData}
						/>
					</Stack>
				</Stack>
				<br></br>
				<Popover
					id={idMP}
					open={openMP}
					anchorEl={filter}
					onClose={handleCloseMP}
					PaperProps={{
						style: {
							padding: "1% 2% 2% 2%",
							width: "25%",
							borderRadius: "10px",
						},
					}}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						spacing={2}
						alignItems="center"
					>
						<Typography sx={{ p: 2 }} variant="h5">
							<b>Filter Report</b>
						</Typography>
						<CloseIcon onClick={handleCloseMP} />
					</Stack>
					<br></br>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<div>
							<Stack sx={{ width: "100%" }} spacing={2}>
								<DatePicker
									openTo="day"
									views={["year", "month", "day"]}
									label="Start"
									value={startDate}
									maxDate={endDate}
									onChange={(newValue) => {
										setStartDate(newValue);
									}}
									renderInput={(params) => (
										<>
											{setStartDateError(params.error)}
											<TextField {...params} helperText={null} />
										</>
									)}
								/>
								<DatePicker
									openTo="day"
									views={["year", "month", "day"]}
									label="End"
									value={endDate}
									minDate={startDate}
									onChange={(newValue) => {
										setEndDate(newValue);
									}}
									renderInput={(params) => (
										<>
											{setEndDateError(params.error)}
											<TextField {...params} helperText={null} />
										</>
									)}
								/>
							</Stack>
						</div>
					</LocalizationProvider>
					<br></br>
					<br></br>
					<Stack direction="row" spacing={2}>
						{startDateError === false && endDateError === false ? (
							<LightBlueContainedButton
								fullWidth
								variant="outlined"

								onClick={handleFilter}
								size="large"
							>
								Filter
							</LightBlueContainedButton>
						) : (
							<LightBlueContainedButton fullWidth variant="outlined" disabled>
								Apply
							</LightBlueContainedButton>
						)}
						<LightBlueContainedButton
							fullWidth
							variant="contained"
							onClick={handlerFilterReset}
							size="large"
						>
							Reset
						</LightBlueContainedButton>
					</Stack>
				</Popover>
				<br></br>
				<StyledTableContainer>
					<Table sx={{ minWidth: 650 }} aria-label="simple table" className={tableSelect.className}>
						<TableHead>
							<TableRow sx={{ backgroundColor: "#F6F6F6" }}>
								<StyledTableCellHeader align="left" sx={{ width: '14%' }}>
									Date
								</StyledTableCellHeader>
								<StyledTableCellHeader align="left" sx={{ width: '14%' }}>
									{transactionType === 'Selling platform' ? 'Description' : 'Transaction Type'}
								</StyledTableCellHeader>
								<StyledTableCellHeader align="left" sx={{ width: '14%' }}>
									Order ID
								</StyledTableCellHeader>
								<StyledTableCellHeader align="right" sx={{ width: '14%' }}>
									Available Balance
								</StyledTableCellHeader>
								<StyledTableCellHeader align="right" sx={{ width: '14%' }}>
									Receivable Balance
								</StyledTableCellHeader>
								<StyledTableCellHeader align="right" sx={{ width: '14%' }}>
									Marketplace Balance
								</StyledTableCellHeader>
								<StyledTableCellHeader align="center" sx={{ width: '14%' }}>
									Status
								</StyledTableCellHeader>
							</TableRow>
						</TableHead>
						<TableBody>
							{isLoading ? (
								<LoadingBox>
									<CircularProgress />
								</LoadingBox>
							) : (
								<>
									{transactionBreakdown.length === 0 ? (
										<TableRow>
											<StyledTableCellBody
												colSpan={8}
												align="center"
												sx={{ padding: "10%" }}
											>
												<Typography variant="h5">
													<b>No results found</b>
												</Typography>
											</StyledTableCellBody>
										</TableRow>
									) : (
										transactionBreakdown
											.sort((a, b) => parseFloat(b.createdTime) - parseFloat(a.createdTime))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((row, index) => (
												<>
													<TableRow hover key={index}>
														<StyledTableCellBody align="left">
															{Moment(requestDate).format("MMM D, y")}
														</StyledTableCellBody>
														<StyledTableCellBody align="left">
															{transactionType === 'Selling platform' ? row.description : (transactionType === 'Refunds' ? row.note : transactionType)}
														</StyledTableCellBody>
														<StyledTableCellBody align="left">
															<Tooltip title={row.ref1?.replace("AMAZON+", "")} placement="left">
																<span>
																	{showLastSevenDigits(row.ref1?.replace("AMAZON+", ""))}
																</span>
															</Tooltip>
														</StyledTableCellBody>
														<StyledTableCellBody align="right" sx={{ color: row.amount <= 0 ? "" : "#00AD47" }} >
															<Typography variant={row.amount <= 0 ? "amountsFont" : "amountsFontBold"}>
																{formatCurrency(row.amount2)}
															</Typography>
														</StyledTableCellBody>
														<StyledTableCellBody align="right">
															<Typography variant="amountsFont">
																{formatCurrency(row.amount3)}
															</Typography>
														</StyledTableCellBody>
														<StyledTableCellBody align="right">
															<Typography variant="amountsFont">
																{formatCurrency(row.amount)}
															</Typography>
														</StyledTableCellBody>
														<StyledTableCellBody align="center">
															<Stack alignItems="center">
																{(() => {
																	if (row.description === "REALISED") {
																		return (
																			<GreenBox>
																				<b>Realised</b>
																			</GreenBox>
																		);
																	} else if (row.description === "REJECTED") {
																		return (
																			<RedBox>
																				<b>Declined</b>
																			</RedBox>
																		);
																	} else if (row.description === "EXPECTED") {
																		return (
																			<YellowBox>
																				<b>Expected</b>
																			</YellowBox>
																		);
																	} else {
																		return (
																			<BlueBox>
																				<b>Pending</b>
																			</BlueBox>
																		);
																	}
																})()}
															</Stack>
														</StyledTableCellBody>
													</TableRow>
												</>
											))
									)}
								</>
							)}
						</TableBody>
					</Table>
				</StyledTableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={transactionBreakdown.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Box>
			<br></br>
		</div>
	);
}
