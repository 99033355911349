import React from "react";
import { useState, useEffect } from "react";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Highcharts from "highcharts";
import CircleIcon from "@mui/icons-material/Circle";
import HighchartsReact from "highcharts-react-official";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Typography,
  Stack,
  IconButton,
  Divider,
  Grid,
  Avatar,
  Collapse,
  Badge,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import { TextButton } from "../../components/ui/Buttons";
import { formatCurrency, removeNegative } from "../../utils/utilityFunctions";
import useMediaQuery from "@mui/material/useMediaQuery";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import solidGauge from "highcharts/modules/solid-gauge";
import hcMore from 'highcharts/highcharts-more';
import amazonLogo from '../../assets/amazonlogosmall.svg'
import { CardBox } from "../../components/ui/Box";

hcMore(Highcharts);
solidGauge(Highcharts);

export default function TransactionDetails(props) {
  const matches = useMediaQuery("(min-width:768px)");
  const [data, setData] = useState(props.data);
  const [open] = useState(true);

  useEffect(() => {
    setData(props.data);
    // eslint-disable-next-line
  }, []);

  if (!data) return null;

  const chartOptions = {
    title: {
      text: "",
    },
    chart: {
      type: "pie",
      height: 229.08,
      width: 229.08,
    },
    yAxis: {
      title: false
    },
    xAxis: {
      lineWidth: 0
    },
    colors: ["#35BD7D", "#FFB701"],
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false,
    }, // The water mark removal place
  };

  return (
    <CardBox>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ pb: 1 }}
      >
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center">
            <IconButton>
              <Avatar alt={data.marketplaceName} src={amazonLogo} />
            </IconButton>
            <Typography variant="h4">{data.marketplaceName}</Typography>
            {/* <IconButton onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton> */}
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
            {/* <TextButton variant="text">
              <CalendarMonthIcon
                sx={{ display: { xs: "block", sm: "none" } }}
              />
              <Typography
                variant="h5"
                sx={{
                  display: { xs: "none", sm: "block" },
                }}
              >
                This month
              </Typography>
              <ExpandMoreIcon sx={{ display: { xs: "none", sm: "block" } }} />
            </TextButton> */}
            {/* <TextButton variant="text">
              <FileDownloadOutlinedIcon />
              <Typography
                sx={{
                  display: { xs: "none", sm: "block" },
                }}
              >
                &nbsp;Export statement
              </Typography>
            </TextButton> */}
          </Stack>
        </Grid>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Divider />
        <Grid
          container
          rowSpacing={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item sm={6} sx={{ pl: matches ? 7 : 0, mt: 1 }}>
            <Grid container>
              <Grid item md={12}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="h5" sx={{ color: "#093BA7", pb: 1 }}>
                    Marketplace Summary
                  </Typography>
                  {/* <IconButton>
                    <ExpandMoreIcon sx={{ color: "#093BA7" }} />
                  </IconButton> */}
                </Stack>
              </Grid>
              <Grid container sx={{ pb: 3 }}>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="h4_bold">
                      {formatCurrency(data.fundedAmount, true)}
                    </Typography>
                    <Typography variant="h5" sx={{ color: "#868B98" }}>
                      Available Balance &nbsp;
                      <Badge
                        badgeContent={
                          <Tooltip
                            disableFocusListener
                            disableTouchListener
                            title="70% of the Net Sales Amount, after deducting Marketplace Charges and Adjustments."
                          >
                            <InfoOutlinedIcon
                              sx={{
                                color: "#989898",
                                marginLeft: "100%",
                                fontSize: 16
                              }}
                            />
                          </Tooltip>
                        }
                      />
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="h4">
                      {formatCurrency(data.receivableBalance, true)}
                    </Typography>
                    <Typography variant="h5" sx={{ color: "#868B98" }}>
                      Receivable Balance &nbsp;
                      <Badge
                        badgeContent={
                          <Tooltip
                            disableFocusListener
                            disableTouchListener
                            title="Remaining Receivable Amount minus the VePay Fee and any Marketplace Adjustments"
                          >
                            <InfoOutlinedIcon
                              sx={{
                                color: "#989898",
                                marginLeft: "100%",
                                fontSize: 16
                              }}
                            />
                          </Tooltip>
                        }
                      />
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="h4">
                      {formatCurrency(data.marketplaceBalance, true)}
                    </Typography>
                    <Typography variant="h5" sx={{ color: "#868B98" }}>
                      Marketplace Balance
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm={6}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item container xs={12} sm={2}></Grid>
            <Grid item container xs={4}>
              <Grid item md={12} sx={{ pb: 3 }}>
                <Stack direction="row" spacing={2}>
                  <CircleIcon sx={{ color: "#35BD7D" }} fontSize="small" />
                  <Stack>
                    <Typography variant="body1">Total Net Sales</Typography>
                    <Typography variant="h4">
                      {formatCurrency(data.totalNetSales, true)}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={12}>
                <Stack direction="row" spacing={2}>
                  <CircleIcon sx={{ color: "#FFB701" }} fontSize="small" />
                  <Stack>
                    <Typography variant="body1">Total Net Returns</Typography>
                    <Typography variant="h4">
                      {formatCurrency(data.totalNetReturns, true)}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid container item xs={8} sm={6} justifyContent="flex-end">
              <HighchartsReact
                options={{
                  ...chartOptions,
                  series: [
                    {
                      name: "Total ($)",
                      data: [
                        { name: "Sales", y: removeNegative(data.totalNetSales) },
                        { name: "Returns", y: removeNegative(data.totalNetReturns) },
                      ],
                      innerSize: "50%",
                      showInLegend: false,
                      dataLabels: {
                        enabled: false,
                      },
                    },
                  ],
                }}
                highcharts={Highcharts}
              />
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </CardBox>
  );
}
